<template>
  <v-app>
    <v-snackbar v-model="snackbar">
      <v-icon class="primary--text pa-1">mdi-alert</v-icon>
      <span class="white--text">Mauvais identifiants. Veuillez réessayer.</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col style="margin-top: -100px" cols="12" sm="8" md="6">
          <v-card :loading="loading" class="opa elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Invitation Cartovia</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon large target="_blank" v-on="on">
                    <v-icon>mdi-login</v-icon>
                  </v-btn>
                </template>
                <span class="white--text">Se connecter</span>
              </v-tooltip>
            </v-toolbar>

            <v-row align="center" justify="center" class="pa-5 ma-2">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>


<script>
import xhr from "../plugins/axios";
import { initializeApp } from 'firebase/app';
import 'firebase/compat/auth';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { OAuthProvider } from "firebase/auth";
// TODO: Replace the following with your app's Firebase project configuration

export default {
  name: "Autologin",
  data() {
    return {
      snackbar: false,
      loading: true,
      uid: null,
      id: null,
      valid: true,
      email: "",
      password: "",
      nameRules: [
        (v) => !!v || "Mot de passe obligatoire",
        (v) =>
          (v && v.length >= 8) ||
          "Mot de passe doit faire au moins 8 caractères",
      ]
    }
  },
  async created() {
    // console.log("params", this.$route.params);


    let firebaseConfig = {
      apiKey: "AIzaSyAI1ZOHrb1zKj-f1Iu6r6BTG87t5qbfUeE",
      authDomain: "cartovia-3a989.firebaseapp.com",
      projectId: "cartovia-3a989",
      storageBucket: "cartovia-3a989.appspot.com",
      messagingSenderId: "1080134626602",
      appId: "1:1080134626602:web:13828952dd2badd70b4253"
    };



    if (this.$route.params.uid && this.$route.params.id) {

      firebaseConfig = {
        apiKey: "AIzaSyBRdPtG4HaY4LMh9bYSUnMnKsXYvY9HAAY",
        authDomain: "authentification-fdnauc.firebaseapp.com",
        databaseURL: "https://authentification-fdnauc.firebaseio.com",
        projectId: "authentification-fdnauc",
        storageBucket: "authentification-fdnauc.appspot.com",
        messagingSenderId: "126825964385",
        appId: "1:126825964385:web:ee5358af3e22157852db20",
        measurementId: "G-47VM6GFGFT"
      }

      await initializeApp(firebaseConfig);

      this.uid = this.$route.params.uid
      this.id = this.$route.params.id
      this.login(this.uid, this.id)
    }

  },
  methods: {
    login(uid, id) {
      this.loading = true;
      xhr
        .post("/autologin-classical", { uid, id }).then((res) => {

          if (!res.data || res.data === null || res.data.success === false) {
            this.snackbar = true;
            this.loading = false;
          } else if (res.data.success === true) {
            this.loading = false;

            localStorage.setItem("uid", uid);
            localStorage.setItem("email", res.data.user.email);
            localStorage.setItem("user", JSON.stringify(res.data.user));

            console.log(res.data)

            window.location = `/inventaire/${this.id}?invited=true`;
          }
        })
    }
  }
}
</script>